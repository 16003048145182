import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import previewImage from 'images/preview.png'

const SEO = ({ path, title, description, keywords }) => {
  const { site } = useStaticQuery(query)
  const { defaultUrl, defaultTitle, defaultDescription, author } =
    site?.siteMetadata

  const metaTitle = title || defaultTitle
  const metaDescription = description || defaultDescription
  const metaUrl = path ? `${defaultUrl}${path.substring(1)}` : defaultUrl

  return (
    <Helmet
      htmlAttributes={{ lang: 'pt' }}
      title={metaTitle}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:url`,
          content: metaUrl
        },
        {
          property: `og:title`,
          content: metaTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:image`,
          content: previewImage
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: author
        },
        {
          name: `twitter:title`,
          content: metaTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          property: `twitter:image`,
          content: previewImage
        },
        ...(keywords?.length
          ? [
              {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            ]
          : [])
      ]}
    />
  )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultUrl: url
        author
      }
    }
  }
`

export default SEO
